import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [myLanguage, setMyLanguage] = useState();
  const [myStyle, setMyStyle] = useState();
  const [siteLanguage, setSiteLanguage] = useState("");
  const [theme, setTheme] = useState(
    localStorage.getItem("theme-mode") === "dark" ? "dark" : "light"
  );

  useEffect(() => {
    const lang = navigator.language;
    const dil = lang === "tr-TR" || lang === "tr" ? "tr" : "en";
    setSiteLanguage(dil);
    getStyle();
    getlanguage(dil);
    // eslint-disable-next-line
  }, [theme]);
  const getlanguage = async (dil) => {
    const bodyData = {
      data: {
        lang: dil,
        requests: {
          alert_succes_message: "",
          landing_home: "",
          landing_about: "",
          landing_services: "",
          landing_products: "",
          landing_contact: "",
          navbar_button3: "",
          brand: "",
          brand_info: "",
          brand_info2: "",
          landing_discover: "",
          signup_box_title: "",
          landing_info1: "",
          landing_info2: "",
          landing_info3: "",
          landing_info4: "",
          about_info1: "",
          about_info2: "",
          about_info3: "",
          about_info4: "",
          about_info5: "",
          about_info6: "",
          about_info7: "",
          product_info1: "",
          product_info2: "",
          product_info3: "",
          product_info4: "",
          product_info5: "",
          product_info6: "",
          product_info7: "",
          product_info8: "",
          product_info9: "",
          product_info10: "",
          product_info11: "",
          product_info12: "",
          product_info13: "",
          services_info1: "",
          services_info2: "",
          services_info3: "",
          services_info4: "",
          services_info5: "",
          services_info6: "",
          services_info7: "",
          services_info8: "",
          our_info1: "",
          our_info2: "",
          our_info3: "",
          detail: "",
          show: "",
          our_info4: "",
          our_info5: "",
          certificate_info1: "",
          certificate_info2: "",
          certificate_info3: "",
          certificate_info4: "",
          certificate_info5: "",
          certificate_info6: "",
          certificate_info7: "",
          form_mail: "",
          form_phone: "",
          form_name: "",
          form_surname: "",
          contact_info1: "",
          contact_info2: "",
          contact_info3: "",
          contact_subject: "",
          contact_message: "",
          table_send: "",
          brand_detail: "",
          brand_links: "",
          sending_info: "",
          sending: "",
          brand_name:"",
          software_team:"",
          designed_by:"",
          owner_company:"",
          all_rights:"",
          owner_company_mail:"",
          owner_company_phone:"",
          owner_company_city:"",
        },
      },
    };

    await fetch(
      `/api/dictionary/getlanguage`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        if (jsonResponse.data === undefined) {
        } else if (jsonResponse.data.type === "OK" && jsonResponse.data?.requests?.brand_name !== "") {
          setMyLanguage(replaceBrandName(jsonResponse.data?.requests, jsonResponse.data?.requests?.brand_name))
        } else if (
          jsonResponse.data.type === "OK" && jsonResponse.data?.requests?.brand_name === ""
        ){
          setMyLanguage(replaceBrandName(jsonResponse.data?.requests, "Brand Name"))
        } 
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function replaceBrandName(obj, brandName) {
    const updatedObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === "object" && value !== null) {
          updatedObj[key] = replaceBrandName(value, brandName);
        } else {
          updatedObj[key] = value.includes("{{brand_name}}")
            ? value.replace(/{{brand_name}}/g, brandName)
            : value;
        }
      }
    }
    return updatedObj;
  }
  const getStyle = async () => {
    const bodyData = {
      data: {
        requests: {
          favicon: "",
          logo:"",
          font_import: "",
          landing_bg_image: "",
          font_family: "",
          font_weight1: "",
          font_weight2: "",
          font_weight3: "",
          text_color1: "",
          text_color2: "",
          icon_color: "",
          landing_hero:"",
        },
      },
    };
    await fetch(
      `/api/dictionary/getstyle`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        if (jsonResponse.data === undefined) {
        } else if (jsonResponse.data.type === "OK") {
          setMyStyle(jsonResponse.data.requests);
        }
      })

      .catch((error) => {});
  };

  return (
    <AuthContext.Provider
      value={{
        myLanguage,
        myStyle,
        setTheme,
        theme,
        siteLanguage,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
