import React from "react";
import "./App.css";
import AuthContextProvider from "./contexts/AuthContext";
import Main from "./components/Main";
import Head from "./components/Head";

function App() {


  return (
    <div className="App">
      <AuthContextProvider>
        <Head/>
        <Main/>
      </AuthContextProvider>
    </div>
  );
}

export default App;
