import React from "react";
import "../App.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useState } from "react";
import { NavLink } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";
import { isMobile } from "react-device-detect";

const Main = () => {
  const { myLanguage, myStyle, siteLanguage } = React.useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showProducts, setshowProducts] = useState(false);
  const [selectTab, setselectTab] = useState("pduDevices");

  const sendMail = async (e) => {
    e.preventDefault();
    const bodyData = {
      data: {
        name,
        email,
        surname,
        number,
        subject,
        message,
      },
    };
    await fetch(`/api/main/contact`, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        if (jsonResponse.data?.type === "OK") {
          MySwal.fire({
            customClass: "alert-body",
            position: "center",
            icon: "success",
            title: `${myLanguage?.alert_succes_message}`,
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0097a7",
          }).then((willDelete) => {
            if (willDelete) {
              window.location.reload(true);
            }
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function downloadPdf(pdfName) {
    const link = document.createElement("a");
    link.download = `${pdfName}`;
    link.href = `../assets/${pdfName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div>
      <header
        className="d-flex align-items-center header sticky-top"
        id="header"
        style={{ background: "#212129" }}
      >
        <div className="container-fluid d-flex justify-content-between align-items-center container-xl">
          <NavLink className="d-flex align-items-center logo" href="/">
            <img alt="" src={`${myStyle?.logo}`} width={50} height={50} />
            <h1 style={{ fontFamily: '"Titillium Web", sans-serif' }}>
              {myLanguage?.brand_name}
            </h1>
          </NavLink>
          <nav id="navbar" className="navbar ms-auto">
            <ul>
              <li>
                <NavLink href="#hero" onClick={() => setshowProducts(false)}>
                  {myLanguage?.landing_home}
                </NavLink>
              </li>
              <li>
                <NavLink href="#about" onClick={() => setshowProducts(false)}>
                  {myLanguage?.landing_about}
                </NavLink>
              </li>
              <li>
                <NavLink
                  href="#services"
                  onClick={() => setshowProducts(false)}
                >
                  {myLanguage?.landing_services}
                </NavLink>
              </li>
              <li>
                <a href="#contact" onClick={() => setshowProducts(false)}>
                  {myLanguage?.landing_contact}
                </a>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    setshowProducts(true);
                    window.scrollTo(0, 0);
                  }}
                >
                  {siteLanguage === "en" ? "Products" : "Ürünler"}
                </NavLink>
              </li>
              <li>
                <NavLink style={{ color: "#b37b32" }} href="./auth/login">
                  <strong>{myLanguage?.navbar_button3}</strong>
                </NavLink>
              </li>
            </ul>
          </nav>
          {isMobile ? (
            <ul
              className="text-start my-auto d-block d-lg-none d-xl-none ms-auto me-2"
              style={{ listStyleType: "none" }}
            >
              <li>
                <NavLink
                  className="w-100"
                  style={{ color: "#b37b32" }}
                  href="/auth/login"
                >
                  <strong className="w-100">
                    {myLanguage?.navbar_button3}
                  </strong>
                </NavLink>
              </li>
            </ul>
          ) : (
            <ul
              className="text-start my-auto d-block d-xl-none ms-auto me-2"
              style={{ listStyleType: "none" }}
            >
              <li>
                <NavLink
                  className="w-100"
                  style={{ color: "#b37b32" }}
                  href="/auth/login"
                >
                  <strong className="w-100">
                    {myLanguage?.navbar_button3}
                  </strong>
                </NavLink>
              </li>
            </ul>
          )}
          <button className="navbar-toggler">
            <i
              className="mobile-nav-toggle mobile-nav-show bi bi-list"
              onClick={() => setIsOpen(!isOpen)}
            />
          </button>
        </div>
        {isOpen && (
          <div
            style={{
              position: "absolute",
              top: "90px",
              backgroundColor: "#212129",
              fontFamily: '"Titillium Web", sans-serif',
            }}
            className="text-white w-100"
          >
            <ul className="text-start" style={{ listStyleType: "none" }}>
              <li
                onClick={() => {
                  setIsOpen(false);
                  setshowProducts(false);
                }}
                style={{ color: "white !important" }}
                className="py-2"
              >
                <NavLink href="#hero">{myLanguage?.landing_home}</NavLink>
              </li>
              <li
                onClick={() => {
                  setIsOpen(false);
                  setshowProducts(false);
                }}
                style={{ color: "white !important" }}
                className="py-2"
              >
                <NavLink href="#about">{myLanguage?.landing_about}</NavLink>
              </li>
              <li
                onClick={() => {
                  setIsOpen(false);
                  setshowProducts(false);
                }}
                style={{ color: "white !important" }}
                className="py-2"
              >
                <NavLink href="#services">
                  {myLanguage?.landing_services}
                </NavLink>
              </li>
              <li
                onClick={() => {
                  setIsOpen(false);
                  setshowProducts(false);
                }}
                style={{ color: "white !important" }}
                className="py-2"
              >
                <NavLink href="#contact">{myLanguage?.landing_contact}</NavLink>
              </li>
              <li
                onClick={() => {
                  setIsOpen(false);
                  setshowProducts(true);
                  window.scrollTo(0, 0);
                }}
                style={{ color: "white !important" }}
                className="py-2"
              >
                <NavLink>
                  {siteLanguage === "en" ? "Products" : "Ürünler"}
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </header>
      {showProducts === false ? (
        <>
          <section
            id="hero"
            className="hero"
            style={{ background: 'url("assets/img/hero_bg.png"), #212129' }}
          >
            {" "}
            <br />
            <br />
            <div className="container position-relative">
              <div className="row gy-5" data-aos="fade-in">
                <div className="col-lg-6 text-center text-lg-start d-flex flex-column justify-content-center order-2 order-lg-1">
                  <h2 style={{ fontFamily: '"Titillium Web", sans-serif' }}>
                    {myLanguage?.brand}
                  </h2>
                  <p style={{ fontFamily: "Poppins, sans-serif" }}>
                    {myLanguage?.brand_info}
                  </p>
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <NavLink
                      href="#about"
                      className="btn-get-started"
                      style={{
                        background: "#b37b32",
                        fontFamily: "Poppins, sans-serif",
                        margin: 0,
                        marginRight: 16,
                      }}
                    >
                      {myLanguage?.landing_discover}
                    </NavLink>
                    <a
                      href="./auth/register"
                      className="btn-get-started"
                      style={{
                        background: "transparent",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {myLanguage?.signup_box_title}
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <img
                    className="img-fluid"
                    data-aos="zoom-out"
                    data-aos-delay={100}
                    src={`${myStyle?.landing_hero}`}
                    alt="img"
                    width={546}
                    height={546}
                  />
                </div>
              </div>
            </div>
            <div className="icon-boxes position-relative">
              <div className="container position-relative">
                <div className="row gy-4 mt-5">
                  <div
                    className="col-md-6 col-xl-3"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    <div
                      className="icon-box"
                      style={{
                        background: "#212529",
                        borderBottomStyle: "solid",
                        borderBottomColor: "#b37b32",
                      }}
                    >
                      <div className="icon">
                        <i
                          className="fas fa-rss"
                          style={{ color: "rgb(179, 123, 50)" }}
                        />
                      </div>
                      <h4 className="title">
                        <a
                          className="stretched-link"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          {myLanguage?.landing_info1}
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-xl-3"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <div
                      className="icon-box"
                      style={{
                        background: "#212529",
                        borderBottomStyle: "solid",
                        borderBottomColor: "#b37b32",
                      }}
                    >
                      <div className="icon">
                        <i
                          className="fa fa-warning"
                          style={{ color: "rgb(179, 123, 50)" }}
                        />
                      </div>
                      <h4 className="title">
                        <a
                          className="stretched-link"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          {myLanguage?.landing_info2}
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-xl-3"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <div
                      className="icon-box"
                      style={{
                        background: "#212529",
                        borderBottomStyle: "solid",
                        borderBottomColor: "#b37b32",
                      }}
                    >
                      <div className="icon">
                        <i
                          className="fas fa-clipboard-list"
                          style={{ color: "rgb(179, 123, 50)" }}
                        />
                      </div>
                      <h4
                        className="title"
                        style={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        <a className="stretched-link">
                          {myLanguage?.landing_info3}
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-xl-3"
                    data-aos="fade-up"
                    data-aos-delay={500}
                  >
                    <div
                      className="icon-box"
                      style={{
                        background: "#212529",
                        borderBottomStyle: "solid",
                        borderBottomColor: "#b37b32",
                      }}
                    >
                      <div className="icon">
                        <i
                          className="fas fa-user-check"
                          style={{ color: "rgb(179, 123, 50)" }}
                        />
                      </div>
                      <h4
                        className="title"
                        style={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        <a className="stretched-link">
                          {myLanguage?.landing_info4}
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="about" className="details">
            {" "}
            <br />
            <br />
            <div className="container">
              <div className="row content">
                <div
                  className="col-md-4 d-flex justify-content-center"
                  data-aos="fade-right"
                >
                  <img
                    className="img-fluid"
                    src={`${myStyle?.logo}`}
                    alt="img"
                    style={{ width: "300px" }}
                  />
                </div>
                <div className="col-md-8 pt-4" data-aos="fade-up">
                  <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
                    <span style={{ color: "rgb(36, 36, 36)" }}>
                      {myLanguage?.about_info1}
                    </span>
                  </h3>
                  <p
                    className="fst-italic"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    <span
                      style={{
                        fontStyle: "normal !important",
                        color: "rgb(36, 36, 36)",
                      }}
                    >
                      {myLanguage?.about_info2}
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontFamily: "Poppins, sans-serif" }}>
                      <span style={{ color: "rgb(36, 36, 36)" }}>
                        {myLanguage?.about_info3}
                      </span>
                    </li>
                    <li style={{ fontFamily: "Poppins, sans-serif" }}>
                      <span style={{ color: "rgb(36, 36, 36)" }}>
                        {myLanguage?.about_info4}
                      </span>
                    </li>
                    <li style={{ fontFamily: "Poppins, sans-serif" }}>
                      <span style={{ color: "rgb(36, 36, 36)" }}>
                        {myLanguage?.about_info5}
                      </span>
                    </li>
                    <li style={{ fontFamily: "Poppins, sans-serif" }}>
                      <span style={{ color: "rgb(36, 36, 36)" }}>
                        {myLanguage?.about_info6}
                      </span>
                    </li>
                  </ul>
                  <p style={{ fontFamily: "Poppins, sans-serif" }}>
                    <span style={{ color: "rgb(36, 36, 36)" }}>
                      {myLanguage?.about_info7}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="services" className="services sections-bg">
            {" "}
            <br />
            <br />
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ color: "rgb(36, 36, 36)" }}>
                    {myLanguage?.landing_services}
                  </span>
                </h2>
                <p style={{ fontFamily: "Poppins, sans-serif" }}>
                  <strong>{myLanguage?.brand_name},</strong>{" "}
                  {myLanguage?.product_info1}
                </p>
              </div>
              <div className="row gy-4" data-aos="fade-up" data-aos-delay={100}>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="service-item position-relative"
                    style={{
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="icon">
                      <i
                        className="bi bi-activity"
                        style={{ color: "#b37b32" }}
                      />
                    </div>
                    <h3
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      {myLanguage?.product_info2}
                    </h3>
                    <p style={{ fontFamily: "Poppins, sans-serif" }}>
                      {myLanguage?.product_info3}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="service-item position-relative"
                    style={{
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="icon">
                      <i
                        className="bi bi-broadcast"
                        style={{ color: "#b37b32" }}
                      />
                    </div>
                    <h3
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      {myLanguage?.product_info4}
                    </h3>
                    <p style={{ fontFamily: "Poppins, sans-serif" }}>
                      {myLanguage?.product_info5}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="service-item position-relative"
                    style={{
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="icon">
                      <i className="bi bi-easel" style={{ color: "#b37b32" }} />
                    </div>
                    <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
                      {myLanguage?.product_info6}
                    </h3>
                    <p style={{ fontFamily: "Poppins, sans-serif" }}>
                      {myLanguage?.product_info7}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="service-item position-relative"
                    style={{
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="icon">
                      <i
                        className="bi bi-bounding-box-circles"
                        style={{ color: "#b37b32" }}
                      />
                    </div>
                    <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
                      {myLanguage?.product_info8}
                    </h3>
                    <p style={{ fontFamily: "Poppins, sans-serif" }}>
                      {myLanguage?.product_info9}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="service-item position-relative"
                    style={{
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="icon">
                      <i
                        className="bi bi-calendar4-week"
                        style={{ color: "#b37b32" }}
                      />
                    </div>
                    <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
                      {myLanguage?.product_info10}
                    </h3>
                    <p style={{ fontFamily: "Poppins, sans-serif" }}>
                      {myLanguage?.product_info11}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className="service-item position-relative"
                    style={{
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="icon">
                      <i
                        className="bi bi-chat-square-text"
                        style={{ color: "#b37b32" }}
                      />
                    </div>
                    <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
                      {myLanguage?.product_info12}
                    </h3>
                    <p style={{ fontFamily: "Poppins, sans-serif" }}>
                      {myLanguage?.product_info13}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            className="container py-4 py-xl-5"
            data-aos="fade-up"
            data-aos-duration={600}
            id="cerfiticate"
          >
            <div
              className="row mb-5"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="col-md-8 col-xl-6 text-center mx-auto">
                <h2
                  style={{
                    color: "#ffffff",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <strong>
                    <span style={{ color: "rgb(36, 36, 36)" }}>
                      {myLanguage?.certificate_info1}
                    </span>
                  </strong>
                </h2>
                <p
                  style={{
                    color: "#ffffff",
                    marginTop: 16,
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <span style={{ color: "rgb(128, 128, 128)" }}>
                    {myLanguage?.certificate_info2}
                  </span>
                </p>
              </div>
            </div>
            <div
              className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div
                className="col"
                style={{ background: "var(--bs-card-cap-bg)" }}
              >
                <div
                  className="card"
                  style={{
                    borderRadius: 12,
                    borderWidth: 0,
                    background: "var(--bs-card-cap-bg)",
                  }}
                >
                  <div
                    className="card-body p-4"
                    data-bss-hover-animate="pulse"
                    style={{
                      background: "#ffffff",
                      opacity: 1,
                      border: "6px none var(--bs-card-cap-bg)",
                      borderRadius: 12,
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <img
                      alt="img"
                      style={{ width: 72, height: 72, marginBottom: 16 }}
                      src="assets/img/certificate%20(1).png"
                    />
                    <h4
                      className="card-title"
                      style={{
                        color: "#ffffff",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      <strong>
                        <span style={{ color: "rgb(36, 36, 36)" }}>
                          ISO 9001
                        </span>
                      </strong>
                    </h4>
                    <p
                      className="card-text"
                      style={{
                        color: "#ffffff",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <span style={{ color: "rgb(128, 128, 128)" }}>
                        {myLanguage?.certificate_info3}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col" style={{ border: "0px dashed #ffffff" }}>
                <div
                  className="card"
                  style={{
                    borderWidth: 0,
                    background: "var(--bs-card-cap-bg)",
                    borderRadius: 12,
                  }}
                >
                  <div
                    className="card-body p-4"
                    data-bss-hover-animate="pulse"
                    style={{
                      background: "#ffffff",
                      color: "#ffffff",
                      borderRadius: 12,
                      borderWidth: 0,
                      borderStyle: "solid",
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <img
                      alt="img"
                      style={{ width: 72, height: 72, marginBottom: 16 }}
                      src="assets/img/certificate%20(2).png"
                    />
                    <h4
                      className="card-title"
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      <strong>
                        <span style={{ color: "rgb(36, 36, 36)" }}>FCC</span>
                      </strong>
                    </h4>
                    <p
                      className="card-text"
                      style={{
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <span style={{ color: "rgb(128, 128, 128)" }}>
                        {myLanguage?.certificate_info4}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  className="card"
                  style={{
                    borderWidth: 0,
                    background: "var(--bs-card-cap-bg)",
                    borderRadius: 12,
                  }}
                >
                  <div
                    className="card-body p-4"
                    data-bss-hover-animate="pulse"
                    style={{
                      background: "#ffffff",
                      color: "#ffffff",
                      borderRadius: 12,
                      borderWidth: 0,
                      borderStyle: "solid",
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <img
                      alt="img"
                      style={{ width: 72, height: 72, marginBottom: 16 }}
                      src="assets/img/certificate%20(3).png"
                    />
                    <h4
                      className="card-title"
                      style={{
                        color: "#ffffff",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      <strong>
                        <span style={{ color: "rgb(36, 36, 36)" }}>
                          ISO 27001
                        </span>
                      </strong>
                    </h4>
                    <p
                      className="card-text"
                      style={{
                        color: "#ffffff",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <span style={{ color: "rgb(128, 128, 128)" }}>
                        {myLanguage?.certificate_info5}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3 d-xl-flex justify-content-xl-center"
              data-aos="fade-up"
              data-aos-duration={600}
              style={{ marginTop: 0 }}
            >
              <div
                className="col"
                style={{ background: "var(--bs-card-cap-bg)" }}
              >
                <div
                  className="card"
                  style={{
                    borderRadius: 12,
                    borderWidth: 0,
                    background: "var(--bs-card-cap-bg)",
                  }}
                >
                  <div
                    className="card-body p-4"
                    data-bss-hover-animate="pulse"
                    style={{
                      background: "#ffffff",
                      opacity: 1,
                      border: "6px none var(--bs-card-cap-bg)",
                      borderRadius: 12,
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <img
                      alt="img"
                      style={{ width: 72, height: 72, marginBottom: 16 }}
                      src="assets/img/certificate%20(4).png"
                    />
                    <h4
                      className="card-title"
                      style={{
                        color: "#ffffff",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      <strong>
                        <span style={{ color: "rgb(36, 36, 36)" }}>RoHS</span>
                      </strong>
                    </h4>
                    <p
                      className="card-text"
                      style={{
                        color: "#ffffff",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <span style={{ color: "rgb(128, 128, 128)" }}>
                        {myLanguage?.certificate_info6}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col" style={{ border: "0px dashed #ffffff" }}>
                <div
                  className="card"
                  style={{
                    background: "var(--bs-card-cap-bg)",
                    borderRadius: 12,
                    borderWidth: 0,
                    borderBottomStyle: "solid",
                    borderBottomColor: "var(--color-secondary)",
                  }}
                >
                  <div
                    className="card-body p-4"
                    data-bss-hover-animate="pulse"
                    style={{
                      background: "#ffffff",
                      color: "#ffffff",
                      borderRadius: 12,
                      boxShadow: "0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15)",
                      borderWidth: 0,
                      borderStyle: "solid",
                      borderBottomStyle: "solid",
                    }}
                  >
                    <img
                      alt="img"
                      style={{ width: 72, height: 72, marginBottom: 16 }}
                      src="assets/img/certificate%20(5).png"
                    />
                    <h4
                      className="card-title"
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      <strong>
                        <span style={{ color: "rgb(36, 36, 36)" }}>CE</span>
                      </strong>
                    </h4>
                    <p
                      className="card-text"
                      style={{
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <span style={{ color: "rgb(128, 128, 128)" }}>
                        {myLanguage?.certificate_info7}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section
            id="contact"
            className="contact"
            style={{ background: "#f6f6f6" }}
          >
            <br />
            <br />
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ color: "rgb(36, 36, 36)" }}>
                    {myLanguage?.landing_contact}
                  </span>
                </h2>
                <p style={{ fontFamily: "Poppins, sans-serif" }}>
                  <span style={{ color: "rgb(128, 128, 128)" }}>
                    {myLanguage?.contact_info1}
                  </span>
                </p>
              </div>
              <div className="row gy-4 gx-lg-0">
                <div className="col-lg-4">
                  <div
                    className="d-flex flex-column justify-content-center align-items-center info-container"
                    style={{ background: "#212129" }}
                  >
                    <div
                      className="d-flex info-item"
                      style={{
                        background: "#444444",
                        marginBottom: 48,
                        borderBottomStyle: "solid",
                        borderBottomColor: "#b37b32",
                      }}
                    >
                      <i className="flex-shrink-0 bi bi-geo-alt" />
                      <div>
                        <h4
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          {myLanguage?.contact_info2}
                        </h4>
                        <p style={{ fontFamily: "Poppins, sans-serif" }}>
                          {myLanguage?.contact_info3}
                          {" " + myLanguage?.owner_company_city}
                        </p>
                      </div>
                    </div>
                    <div
                      className="d-flex info-item"
                      style={{
                        background: "#444444",
                        marginBottom: 48,
                        borderBottomStyle: "solid",
                        borderBottomColor: "#b37b32",
                      }}
                    >
                      <i className="flex-shrink-0 bi bi-envelope" />
                      <div>
                        <h4
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          {myLanguage?.form_mail}
                        </h4>
                        <p style={{ fontFamily: "Poppins, sans-serif" }}>
                          {myLanguage?.owner_company_mail}
                        </p>
                      </div>
                    </div>
                    <div
                      className="d-flex info-item"
                      style={{
                        background: "#444444",
                        borderBottomStyle: "solid",
                        borderBottomColor: "#b37b32",
                      }}
                    >
                      <i className="flex-shrink-0 bi bi-phone" />
                      <div>
                        <h4 style={{ fontFamily: "Poppins, sans-serif" }}>
                          {myLanguage?.form_phone}
                        </h4>
                        <p style={{ fontFamily: "Poppins, sans-serif" }}>
                          {myLanguage?.owner_company_phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <form
                    onSubmit={sendMail}
                    method="post"
                    role="form"
                    className="php-email-form"
                    style={{
                      borderBottomStyle: "solid",
                      borderBottomColor: "#b37b32",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          className="form-control form-control"
                          type="text"
                          name="name"
                          id="name"
                          placeholder={`${myLanguage?.form_name}`}
                          required
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input
                          className="form-control form-control"
                          type="text"
                          name="surname"
                          id="surname"
                          placeholder={`${myLanguage?.form_surname}`}
                          required
                          onChange={(e) => setSurname(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          className="form-control form-control"
                          type="text"
                          name="telephone"
                          id="telephone"
                          placeholder={`${myLanguage?.form_phone}`}
                          required
                          onChange={(e) => setNumber(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input
                          className="form-control form-control"
                          type="email"
                          name="email"
                          id="email"
                          placeholder={`${myLanguage?.form_mail}`}
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <input
                        className="form-control form-control"
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder={`${myLanguage?.contact_subject}`}
                        required
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control form-control"
                        name="message"
                        rows={7}
                        placeholder={`${myLanguage?.contact_message}`}
                        required
                        defaultValue={""}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div className="my-3">
                      <div className="loading">
                        <span>{myLanguage?.sending}</span>
                      </div>
                      <div className="error-message" />
                      <div className="sent-message">
                        <span>{myLanguage?.sending_info}</span>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        style={{
                          background: "#b37b32",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {myLanguage?.table_send}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section
            className="services sections-bg"
            style={{ marginTop: "-16px", background: "#fff" }}
          >
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h2
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ color: "rgb(36, 36, 36)" }}>
                    {siteLanguage === "en" ? "Products" : "Ürünler"}
                  </span>
                </h2>
              </div>
            </div>
            <div className="container">
              <div class="radio-inputs w-100 my-3">
                <label class="radio">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setselectTab("pduDevices")}
                    checked={selectTab === "pduDevices" ? true : false}
                  />
                  <span class="name">
                    {siteLanguage === "en" ? "PDU Devices" : "PDU Cihazları"}
                  </span>
                </label>
                <label class="radio">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setselectTab("pduSensors")}
                    checked={selectTab === "pduSensors" ? true : false}
                  />
                  <span class="name">
                    {siteLanguage === "en" ? "Sensors" : "Sensörler"}
                  </span>
                </label>
                <label class="radio">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setselectTab("envDevices")}
                    checked={selectTab === "envDevices" ? true : false}
                  />
                  <span class="name">
                    {siteLanguage === "en"
                      ? "Env. Monitoring Devices"
                      : "Ortam İzleme Cihazları"}
                  </span>
                </label>
              </div>
            </div>
          </section>
          {selectTab === "pduDevices" ? (
            <>
              <section
                className="services sections-bg"
                style={{ marginTop: "-16px", background: "#fff" }}
              >
                <div className="container" data-aos="fade-up">
                  <div className="section-header">
                    <h2
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      <span style={{ color: "rgb(36, 36, 36)" }}>
                        {siteLanguage === "en"
                          ? "PDU Devices"
                          : "PDU Cihazları"}
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="container">
                  <div className="row row-fitur" data-aos="zoom-out-up">
                    <div
                      className="col-sm-6 col-md-6 col-lg-6 waves-effect kolom-a"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/product1.png"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            GM-BASIC-PDU
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{
                              minHeight: "350px",
                              fontWeight: "lighter",
                            }}
                          >
                            <li>
                              {siteLanguage === "en"
                                ? "Current data of the device can be displayed."
                                : "Cihazın güncel verileri görüntülenebilir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Sensor values and measurement values can be displayed thanks to the OLED screen."
                                : "OLED ekran sayesinde sensör değerleri ve ölçüm değerleri görüntülenebilmektedir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Our basic PDU has single and double fuses."
                                : "Temel PDU'muz tek ve çift sigortalıdır."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "The PDU is also voltage protected and has amper meter."
                                : "PDU ayrıca voltaj korumalıdır ve amper ölçere sahiptir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "The PDU has a switch and can be used at any angle thanks to its rotating side cover."
                                : "PDU anahtarlıdır ve döner yan kapağı sayesinde istenilen açıda kullanılabilir."}
                            </li>
                          </ul>
                        </p>
                        <div className="d-flex justify-content-end">
                          <div
                            className="d-flex align-items-center justify-content-center"
                            onClick={() => downloadPdf("GM-BASIC-PDU")}
                            style={{
                              cursor: "pointer",
                              fontSize: "15px",
                              color: "white",
                              backgroundColor: "#b37b32",
                              width: "150px",
                              height: "40px",
                              borderRadius: "8px",
                            }}
                          >
                            <span className="me-2">
                              {siteLanguage === "en"
                                ? "Download PDF"
                                : "PDF İndir"}
                            </span>
                            <img
                              alt="img"
                              src="assets/img/download.png"
                              style={{ width: "26px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-sm-6 col-md-6 col-lg-6 waves-effect kolom-b"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/product1.png"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            GM-OLED-IP-PDU
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{
                              minHeight: "350px",
                              fontWeight: "lighter",
                            }}
                          >
                            <li>
                              {siteLanguage === "en"
                                ? "There are two sensor inputs that can be installed externally, one for liquid and the other for temperature-humidity monitoring."
                                : "Biri sıvı, diğeri sıcaklık-nem takibi için harici olarak takılabilen iki adet sensör girişi bulunmaktadır."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Data of the device such as voltage, current, frequency, power and total energy consumption can be displayed."
                                : "Cihazın voltaj, akım, frekans, güç ve toplam enerji tüketimi gibi verileri görüntülenebilmektedir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Thanks to the local interface, the measurements of the relevant sensors can be viewed and device settings can be changed."
                                : "Lokal arayüz sayesinde ilgili sensörlerin ölçümleri görüntülenebilmekte ve cihaz ayarları değiştirilebilmektedir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "With its SMTP communication structure, it provides the opportunity to be notified by e-mail in case of alarm."
                                : "SMTP iletişim yapısı ile alarm durumunda e-posta ile bilgilendirilme imkanı sağlar."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Thanks to the SNMP network management protocol, current, voltage, power, frequency, total consumed energy, and sensor data can be received with predetermined OID requests."
                                : "SNMP ağ yönetim protokolü sayesinde önceden belirlenen OID istekleri ile akım, gerilim, güç, frekans, toplam tüketilen enerji ve sensör verileri alınabilmektedir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Up to 1500 logs of data can be saved in the device memory."
                                : "Cihaz hafızasına 1500'e kadar veri günlüğü kaydedilebilir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Sensor values and measurement values can be  displayed thanks to the OLED screen."
                                : "OLED ekran sayesinde sensör değerleri ve ölçüm değerleri görüntülenebilmektedir."}
                            </li>
                          </ul>
                        </p>

                        <div className="d-flex justify-content-end">
                          <div
                            className="d-flex align-items-center justify-content-center"
                            onClick={() => downloadPdf("GM-OLED-IP-PDU")}
                            style={{
                              cursor: "pointer",
                              fontSize: "15px",
                              color: "white",
                              backgroundColor: "#b37b32",
                              width: "150px",
                              height: "40px",
                              borderRadius: "8px",
                            }}
                          >
                            <span className="me-2">
                              {siteLanguage === "en"
                                ? "Download PDF"
                                : "PDF İndir"}
                            </span>
                            <img
                              alt="img"
                              src="assets/img/download.png"
                              style={{ width: "26px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-sm-6 col-md-6 col-lg-6 waves-effect kolom-c"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/product2.png"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            GM-Switched-PDU
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <span style={{ color: "rgb(128, 128, 128)" }}>
                            <ul
                              className="text-black"
                              style={{
                                minHeight: "350px",
                                fontWeight: "lighter",
                              }}
                            >
                              <li>
                                {siteLanguage === "en"
                                  ? "There are 3 sensor inputs that can be installed externally, one of which can be used for liquid and the other two for temperature-humidity monitoring."
                                  : "Biri sıvı, diğer ikisi sıcaklık-nem takibi için kullanılabilen, harici olarak monte edilebilen 3 adet sensör girişi bulunmaktadır."}
                              </li>
                              <li>
                                {siteLanguage === "en"
                                  ? "Data such as voltage, current, frequency, power, total energy consumption, socket-based current measurement of the device can be displayed. It also has the feature of closing and opening the socket."
                                  : "Cihazın voltaj, akım, frekans, güç, toplam enerji tüketimi, priz bazlı akım ölçümü gibi verileri görüntülenebilmektedir. Ayrıca prizi kapatıp açma özelliği de bulunmaktadır."}
                              </li>
                              <li>
                                {siteLanguage === "en"
                                  ? "Thanks to the local interface, the measurements of the relevant sensors can be viewed and device settings can be changed."
                                  : "Lokal arayüz sayesinde ilgili sensörlerin ölçümleri görüntülenebilmekte ve cihaz ayarları değiştirilebilmektedir."}
                              </li>
                              <li>
                                {siteLanguage === "en"
                                  ? "With its SMTP communication structure, it provides the opportunity to be notified by e-mail in case of alarm."
                                  : "SMTP iletişim yapısı ile alarm durumunda e-posta ile bilgilendirilme imkanı sağlar."}
                              </li>
                              <li>
                                {siteLanguage === "en"
                                  ? "Thanks to the SNMP network management protocol, current, voltage, power, frequency, total consumed energy, open/close status of sockets and data on sensors can be received with predetermined  OID requests. "
                                  : "SNMP ağ yönetim protokolü sayesinde önceden belirlenen OID istekleri ile akım, gerilim, güç, frekans, toplam tüketilen enerji, prizlerin açık/kapalı durumu ve sensörler üzerindeki veriler alınabilmektedir."}
                              </li>
                              <li>
                                {siteLanguage === "en"
                                  ? "Up to 1500 logs of data can be saved in the device memory."
                                  : "Cihaz hafızasına 1500'e kadar veri günlüğü kaydedilebilir."}
                              </li>
                              <li>
                                {siteLanguage === "en"
                                  ? "Sensor values can be viewed, socket opening/closing operations can be performed and device settings can be made with the touch screen."
                                  : "Dokunmatik ekran üzerinden sensör değerleri görüntülenebilmekte, priz açma/kapama işlemleri yapılabilmekte ve cihaz ayarları yapılabilmektedir."}
                              </li>
                            </ul>
                          </span>
                        </p>
                        <div className="d-flex justify-content-end">
                          <div
                            className="d-flex align-items-center justify-content-center"
                            onClick={() => downloadPdf("GM-Switched-PDU")}
                            style={{
                              cursor: "pointer",
                              fontSize: "15px",
                              color: "white",
                              backgroundColor: "#b37b32",
                              width: "150px",
                              height: "40px",
                              borderRadius: "8px",
                            }}
                          >
                            <span className="me-2">
                              {siteLanguage === "en"
                                ? "Download PDF"
                                : "PDF İndir"}
                            </span>
                            <img
                              alt="img"
                              src="assets/img/download.png"
                              style={{ width: "26px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-sm-6 col-md-6 col-lg-6 waves-effect kolom-c"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/product2.png"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            GM-PORT-BASED-PDU
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{
                              minHeight: "350px",
                              fontWeight: "lighter",
                            }}
                          >
                            <li>
                              {siteLanguage === "en"
                                ? "There are 3 sensor inputs  that can be installed externally, one of which can be used for liquid and the other two for temperature- humidity monitoring."
                                : "Biri sıvı, diğer ikisi sıcaklık-nem takibi için kullanılabilen, harici olarak monte edilebilen 3 adet sensör girişi bulunmaktadır."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Data of the device such as voltage, current, frequency, power and total energy consumption can be displayed. It also has a socket-based monitoring feature."
                                : "Cihazın voltaj, akım, frekans, güç ve toplam enerji tüketimi gibi verileri görüntülenebilmektedir. Ayrıca soket tabanlı izleme özelliğine de sahiptir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Thanks to the local interface, the measurements of the relevant sensors can be viewed and device settings can be changed."
                                : "Lokal arayüz sayesinde ilgili sensörlerin ölçümleri görüntülenebilmekte ve cihaz ayarları değiştirilebilmektedir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "With its SMTP communication structure, it provides the opportunity to be notified by e-mail in case of alarm."
                                : "SMTP iletişim yapısı ile alarm durumunda e-posta ile bilgilendirilme imkanı sağlar."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Thanks to the SNMP network management protocol, data on current, voltage, power, frequency, total consumed energy, socket-based measurement statuses and sensors can be received with pre-determined OID requests."
                                : "SNMP ağ yönetim protokolü sayesinde önceden belirlenen OID istekleri ile akım, gerilim, güç, frekans, toplam tüketilen enerji, soket bazlı ölçüm durumları ve sensörlere ilişkin veriler alınabilmektedir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Up to 1500 logs of data can be saved in the device memory."
                                : "Cihaz hafızasına 1500'e kadar veri günlüğü kaydedilebilir."}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Sensor values can be viewed and device settings can be made with the touch screen. "
                                : "Dokunmatik ekran üzerinden sensör değerleri görüntülenebilmekte ve cihaz ayarları yapılabilmektedir."}
                            </li>
                          </ul>
                        </p>
                        <div className="d-flex justify-content-end">
                          <div
                            className="d-flex align-items-center justify-content-center"
                            onClick={() => downloadPdf("GM-PORT-BASED-PDU")}
                            style={{
                              cursor: "pointer",
                              fontSize: "15px",
                              color: "white",
                              backgroundColor: "#b37b32",
                              width: "150px",
                              height: "40px",
                              borderRadius: "8px",
                            }}
                          >
                            <span className="me-2">
                              {siteLanguage === "en"
                                ? "Download PDF"
                                : "PDF İndir"}
                            </span>
                            <img
                              alt="img"
                              src="assets/img/download.png"
                              style={{ width: "26px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                id="services-1"
                className="services sections-bg"
                style={{ background: "#f6f6f6" }}
              >
                <div className="container" data-aos="fade-up">
                  <div className="section-header">
                    <h2
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      <span style={{ color: "rgb(36, 36, 36)" }}>
                        {siteLanguage === "en"
                          ? "Information"
                          : "Bilgilendirme"}
                      </span>
                    </h2>
                    <p style={{ fontFamily: "Poppins, sans-serif" }}>
                      <strong>
                        <span style={{ color: "black" }}>
                          {myLanguage?.services_info1}
                        </span>
                      </strong>
                      <span style={{ color: "black" }}>
                        {myLanguage?.services_info2}
                      </span>
                    </p>
                  </div>
                  <div
                    className="row gy-4"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    <div className="col">
                      <div className="row space-rows">
                        <div className="col">
                          <div
                            className="card cards-shadown cards-hover"
                            data-aos="flip-left"
                            data-aos-duration={950}
                            style={{
                              background: "rgba(0, 131, 116, 0)",
                              borderRadius: 20,
                            }}
                          >
                            <div
                              className="card-header"
                              style={{
                                background: "#b37b32",
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                              }}
                            >
                              <span className="space">
                                <a>
                                  <i
                                    className="fa fa-wrench"
                                    id="download-icon-1"
                                    style={{ color: "#ffffff" }}
                                  />
                                </a>
                              </span>
                              <div className="cardheader-text">
                                <h4
                                  id="heading-card-1"
                                  style={{
                                    fontSize: isMobile ? 16 : 18,
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                >
                                  <br />
                                  {myLanguage?.services_info3}
                                  <br />
                                  <br />
                                </h4>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{
                                background: "#ffffff",
                                borderBottomLeftRadius: 10,
                                borderBottomRightRadius: 10,
                              }}
                            >
                              <img
                                alt="img"
                                src="assets/img/pdu/pdu_misc%20(1).png"
                                style={{ width: 108, marginBottom: 8 }}
                              />
                              <p
                                className="card-text cardbody-sub-text"
                                style={{
                                  fontWeight: "bold",
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: 24,
                                }}
                              >
                                {myLanguage?.services_info4}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div
                            className="card cards-shadown cards-hover"
                            data-aos="slide-right"
                            data-aos-duration={950}
                            style={{ borderRadius: 20 }}
                          >
                            <div
                              className="card-header"
                              style={{
                                background: "#b37b32",
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                              }}
                            >
                              <span className="space">
                                <a>
                                  <i
                                    className="fa fa-wrench"
                                    id="download-icon-2"
                                    style={{ color: "#ffffff" }}
                                  />
                                </a>
                              </span>
                              <div className="cardheader-text">
                                <h4
                                  id="heading-card-2"
                                  style={{
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: isMobile ? 16 : 18,
                                  }}
                                >
                                  <br />
                                  {myLanguage?.services_info5}
                                </h4>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{
                                background: "#ffffff",
                                borderBottomRightRadius: 10,
                                borderBottomLeftRadius: 10,
                              }}
                            >
                              <img
                                alt="img"
                                src="assets/img/pdu/pdu_misc%20(3).png"
                                style={{ width: 108, marginBottom: 8 }}
                              />
                              <p
                                className="card-text cardbody-sub-text"
                                style={{
                                  fontWeight: "bold",
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: 24,
                                }}
                              >
                                {myLanguage?.services_info6}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div
                            className="card cards-shadown cards-hover"
                            data-aos="flip-up"
                            data-aos-duration={950}
                            style={{ borderRadius: 20 }}
                          >
                            <div
                              className="card-header cards-header-hover"
                              style={{
                                background: "#b37b32",
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                              }}
                            >
                              <span className="space">
                                <a>
                                  <i
                                    className="fa fa-wrench"
                                    id="download-icon-3"
                                    style={{ color: "#ffffff" }}
                                  />
                                </a>
                              </span>
                              <div className="cardheader-text">
                                <h4
                                  id="heading-card-3"
                                  style={{
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: isMobile ? 16 : 18,
                                  }}
                                >
                                  <br />
                                  {myLanguage?.services_info7}
                                </h4>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{
                                background: "#ffffff",
                                borderBottomRightRadius: 10,
                                borderBottomLeftRadius: 10,
                              }}
                            >
                              <img
                                alt="img"
                                src="assets/img/pdu/pdu_misc%20(2).png"
                                style={{ width: 108, marginBottom: 8 }}
                              />
                              <p
                                className="card-text cardbody-sub-text"
                                style={{
                                  fontFamily: "Poppins",
                                  fontWeight: "bold",
                                  fontSize: 24,
                                }}
                              >
                                {myLanguage?.services_info8}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="services sections-bg"
                style={{ background: "#ffffff" }}
              >
                <div className="container" data-aos="fade-up">
                  <div className="section-header">
                    <h2
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      <span style={{ color: "rgb(36, 36, 36)" }}>
                        {" "}
                        {siteLanguage === "en"
                          ? "Smart Screen Technology"
                          : "Akıllı Ekran Teknolojisi"}
                      </span>
                    </h2>
                    <p style={{ fontFamily: "Poppins, sans-serif" }}>
                      <span style={{ color: "black" }}>
                        {siteLanguage === "en"
                          ? "Ethernet settings (IP assignment, DHCP active/passive, etc.) and factory resets can be made via the 2.4 inch TFT touchscreen LCD screen on the smart PDU. Device ID number, current, voltage, frequency, power factor etc. used by the sockets can be seen on the screen.Information about electrical properties can be read."
                          : "Akıllı PDU üzerinde bulunan 2,4 inç TFT dokunmatik LCD ekran üzerinden Ethernet ayarları (IP atama, DHCP aktif/pasif vb.) ve fabrika ayarlarına sıfırlama işlemleri yapılabilmektedir. Prizlerin kullandığı cihaz ID numarası, akım, gerilim, frekans, güç faktörü vb. bilgiler ekranda görülebilir. Elektriksel özellikleri ile ilgili bilgiler okunabilir."}
                      </span>
                    </p>
                  </div>
                  <div
                    className="row gy-4"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    <img
                      alt="img"
                      src="assets/img/smartscreen.png"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </section>
            </>
          ) : null}
          {selectTab === "pduSensors" ? (
            <>
              <section
                className="services sections-bg"
                style={{ marginTop: "-16px", background: "#fff" }}
              >
                <div className="container" data-aos="fade-up">
                  <div className="section-header">
                    <h2
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      <span style={{ color: "rgb(36, 36, 36)" }}>
                        {siteLanguage === "en"
                          ? "Environmental Monitoring And PDU Sensors"
                          : "Ortam İzleme ve PDU Sensörleri"}
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="container">
                  <div className="row row-fitur" data-aos="zoom-out-up">
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 waves-effect kolom-a"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/sensor/smokesensor.png"
                          style={{ height: "200px" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Smoke Sensor"
                              : "Duman Sensörü"}
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{ fontWeight: "lighter" }}
                          >
                            <li>
                              {" "}
                              {siteLanguage === "en"
                                ? "High Sensivity"
                                : "Yüksek Hassasiyet"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Works with 12 Volts"
                                : "12 Volt ile çalışır"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "85 dB Sound Quality"
                                : "85 dB Ses Kalitesi"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Test Button"
                                : "Test Düğmesi"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Optical Sensor"
                                : "Optik Sensör"}
                            </li>

                            <li>
                              {siteLanguage === "en"
                                ? "No Need for Wiring"
                                : "Kablosuz"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Easy Installation"
                                : "Kolay Kurulum"}
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 waves-effect kolom-a"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/sensor/waterleaksensor.png"
                          style={{ height: "200px" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Water-Leak Sensor"
                              : "Su Sızıntısı Sensörü"}
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{ fontWeight: "lighter" }}
                          >
                            <li>
                              {siteLanguage === "en"
                                ? "High Sensivity"
                                : "Yüksek Hassasiyet"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Compatible With EMS systems"
                                : "EMS Sistemleriyle Uyumlu"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Sensor Input In All EMS Systems Can Be Used By Plugging"
                                : "Tüm EMS Sistemlerinde Sensör Girişi Takılarak Kullanılabilir"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Can Be Connected To The Alarm Panel"
                                : "Alarm Paneline Bağlanabilir"}
                            </li>
                            <li>
                              {" "}
                              {siteLanguage === "en"
                                ? "Easy Assembling"
                                : "Kolay Montaj"}
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 waves-effect kolom-a"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/sensor/humsensor.png"
                          style={{ height: "200px" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Heat-Humidity Sensor"
                              : "Isı-Nem Sensörü"}
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{ fontWeight: "lighter" }}
                          >
                            <li>
                              {" "}
                              {siteLanguage === "en"
                                ? "High Precision Measurement"
                                : "Yüksek Hassasiyetli Ölçüm"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Humidity And Temperature Sensor In One Device"
                                : "Tek Cihazda Nem ve Sıcaklık Sensörü"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Calibrated And High Precision Digital Measurement"
                                : "Kalibre Edilmiş ve Yüksek Hassasiyetli Dijital Ölçüm"}
                            </li>
                            <li>
                              {siteLanguage === "en" ? "2m Cable" : "2m Kablo"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Suitable For Working Indoors"
                                : "İç Mekanda Çalışmaya Uygun"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "No Need for Wiring"
                                : "Kablosuz"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Easy Installation"
                                : "Kolay Kurulum"}
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 waves-effect kolom-a"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/sensor/airsensor.png"
                          style={{ height: "200px" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Air-Quality Sensor"
                              : "Hava-Kalite Sensörü"}
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{ fontWeight: "lighter" }}
                          >
                            <li>
                              {siteLanguage === "en"
                                ? "Sulfur, Benzene, Water Vapor, Smoke And Other Harmful Gases (NH3, NOx, Alcohol, CO2 etc.)"
                                : "Kükürt, Benzen, Su Buharı, Duman ve Diğer Zararlı Gazlar (NH3, NOx, Alkol, CO2 vb.)"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Precisely Measures The Concentration"
                                : "Konsantrasyonu Hassas Şekilde Ölçer"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Analog Output"
                                : "Analog Çıkış"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Output Voltage Depends On The Gas Concentration In The Air"
                                : "Çıkış Gerilimi Havadaki Gaz Konsantrasyonuna Bağlıdır"}
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 waves-effect kolom-a"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/sensor/doorsensor.png"
                          style={{ height: "200px" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Magnetic-Door Sensor"
                              : "Manyetik Kapı Sensörü"}
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{ fontWeight: "lighter" }}
                          >
                            <li>
                              {siteLanguage === "en" ? "2m Cable" : "2m Kablo"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "3cm Detection Distance"
                                : "3cm Algılama Mesafesi"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "On / Off Operation"
                                : "Açma/Kapama Çalışması"}
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 waves-effect kolom-a"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/sensor/energysensor.png"
                          style={{ height: "200px" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Energy-Monitoring Sensor"
                              : "Enerji İzleme Sensörü"}
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{ fontWeight: "lighter" }}
                          >
                            <li>
                              {siteLanguage === "en"
                                ? "Monophase And Triphase Operation"
                                : "Monofaze ve Trifaze Çalışma"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Measureing Up To 80 Amperes"
                                : "80 Ampere Kadar Ölçüm"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Compatible With All Devices With RS 485 Connection"
                                : "RS 485 Bağlantılı Tüm Cihazlarla Uyumlu"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Compatible With Environmental Monitoring Sensors"
                                : "Çevresel İzleme Sensörleriyle Uyumlu"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Reading With 0.1% High Precision"
                                : "%0,1 Yüksek Hassasiyetle Okuma"}
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 waves-effect kolom-a"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/sensor/sirensensor.png"
                          style={{ height: "200px" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Flashing-Siren Sensor"
                              : "Yanıp Sönen Siren Sensörü"}
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{ fontWeight: "lighter" }}
                          >
                            <li>
                              {siteLanguage === "en"
                                ? "Resistant To Outdoor Environments"
                                : "Dış Ortamlara Dayanıklı"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Features Both Light And Sound"
                                : "Hem Işık Hem Ses Özelliğine Sahiptir"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "2 Programmable Sound Features Outdoor Siren (With Single Buzzer)"
                                : "2 Programlanabilir Ses Özellikli Dış Mekan Sireni (Tek Buzzerlı)"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "There Is A Time Limitation Feature"
                                : "Zaman Sınırlama Özelliği Vardır"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Resistance To Solid Objects And Water At IP 34 Standards"
                                : "IP 34 Standartlarında Katı Cisimlere ve Suya Dayanım"}
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 waves-effect kolom-a"
                      data-bss-hover-animate="pulse"
                    >
                      <div className="fitur-a">
                        <img
                          alt="img"
                          src="assets/img/sensor/screensensor.png"
                          style={{ height: "200px" }}
                        />
                      </div>
                      <div className="separator-fitur" />
                      <div>
                        <h4 className="heading-fitur">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#b37b32",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Info-Screen Sensor"
                              : "Bilgi Ekranı Sensörü"}
                          </span>
                        </h4>
                        <p
                          className="paragraf-fitur"
                          style={{ fontFamily: "Poppins, sans-serif" }}
                        >
                          <ul
                            className="text-black"
                            style={{ fontWeight: "lighter" }}
                          >
                            <li>
                              {siteLanguage === "en"
                                ? "2X8 LCD Screen"
                                : "2X8 LCD Ekran"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Internal Temperature Humidity Sensor"
                                : "Dahili Sıcaklık Nem Sensörü"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "You Can See All Instant Data On The LCD"
                                : "Tüm Anlık Verileri LCD Ekranda Görebilirsiniz"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "High Sensitivity"
                                : "Yüksek Hassasiyet"}
                            </li>
                            <li>
                              {siteLanguage === "en"
                                ? "Siren Can Be Connected With Digital Output"
                                : "Siren Dijital Çıkışla Bağlanabilir"}
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : null}
          {selectTab === "envDevices" ? (
            <section
              id="products"
              className="services sections-bg"
              style={{ marginTop: "-16px", background: "#fff5" }}
            >
              <br />
              <br />
              <div className="container" data-aos="fade-up">
                <div className="section-header">
                  <h2
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    <span style={{ color: "rgb(36, 36, 36)" }}>
                      {siteLanguage === "en"
                        ? "Environmental Monitoring Devices"
                        : "Ortam İzleme Cihazları"}
                    </span>
                  </h2>
                  <p style={{ fontFamily: "Poppins, sans-serif" }}>
                    <span style={{ color: "rgb(128, 128, 128)" }}>
                      {myLanguage?.our_info1}
                    </span>
                    <br />
                    <span style={{ color: "rgb(128, 128, 128)" }}>
                      {myLanguage?.our_info2}
                    </span>
                  </p>
                </div>
              </div>
              <div className="container">
                <div className="row row-fitur" data-aos="zoom-out-up">
                  <div
                    className="col-lg-4 col-md-12 col-sm-12 waves-effect kolom-a"
                    data-bss-hover-animate="pulse"
                  >
                    <div
                      className="fitur-a d-flex align-items-center justify-content-center"
                      style={{ width: "100%", height: "250px" }}
                    >
                      <img
                        alt="img"
                        src="assets/img/product%20(1).png"
                        style={{ width: 320 }}
                      />
                    </div>
                    <div className="separator-fitur" />
                    <div>
                      <h4 className="heading-fitur">
                        <span
                          className="badge"
                          style={{
                            backgroundColor: "#b37b32",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          GM-EMS-301
                        </span>
                      </h4>
                      <h4 className="heading-fitur">
                        <span
                          className="badge me-1 my-1"
                          style={{
                            border: "1px solid #b37b32",
                            fontFamily: "Poppins, sans-serif",
                            color: "#b37b32",
                          }}
                        >
                          {siteLanguage === "en"
                            ? "Heat Sensor"
                            : "Isı Sensörü"}
                        </span>
                        <span
                          className="badge me-1 my-1"
                          style={{
                            border: "1px solid #b37b32",
                            fontFamily: "Poppins, sans-serif",
                            color: "#b37b32",
                          }}
                        >
                          {siteLanguage === "en"
                            ? "Humidity Sensor"
                            : "Nem Sensörü"}
                        </span>
                        <span
                          className="badge"
                          style={{
                            border: "1px solid #b37b32",
                            fontFamily: "Poppins, sans-serif",
                            color: "#b37b32",
                          }}
                        >
                          {siteLanguage === "en"
                            ? "Camera Option"
                            : "Kamera Seçeneği"}
                        </span>
                      </h4>
                      <p
                        className="paragraf-fitur"
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          minHeight: "275px",
                        }}
                      >
                        <span style={{ color: "rgb(128, 128, 128)" }}>
                          {/* {myLanguage?.our_info3} */}
                          {siteLanguage === "en"
                            ? "It is compatible with sensors of industrial standards,is easy to install, and offers flexible and reliableservice. With the LAN Ethernet connection standard in Guardmon EMS 301, you can easily include your Lande EMS MINI 301 in your local network. With EMS 301, you can remotely monitor from anywhere in the world with secure web access. You will be instantly notified of alarm notifications in many ways such as e-mail, SMS, SNMP trap."
                            : "Endüstriyel standartlardaki sensörlerle uyumlu, kurulumu kolay, esnek ve güvenilir hizmet sunar. Guardmon EMS 301'de bulunan LAN Ethernet bağlantı standardı ile Lande EMS MINI 301'inizi yerel ağınıza kolaylıkla dahil edebilirsiniz. EMS 301 ile güvenli web erişimi ile dünyanın her yerinden uzaktan izleme yapabilirsiniz. Alarm bildirimlerinden e-posta, SMS, SNMP trap gibi birçok yolla anında haberdar olacaksınız."}
                        </span>
                      </p>
                      {/* <button
                        style={{ borderRadius: 8 }}
                        className="button"
                        type="button"
                        data-bss-hover-animate="pulse"
                        data-bs-toggle="modal"
                        data-bs-target="#product-1"
                        data-hover={`${myLanguage?.show}`}
                      >
                        <span>{myLanguage?.detail}</span>
                      </button> */}
                      <div className="mt-3">
                        <div className="row">
                          <div
                            className="col-12 p-3 text-center w-100"
                            style={{
                              backgroundColor: "#b37b32",
                              color: "white",
                              borderTopRightRadius: "8px",
                              borderTopLeftRadius: "8px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Technical Features"
                              : "Teknik Özellikler"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Size" : "Boyut"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            380 x 105 x 35 mm
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Weight" : "Ağırlık"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            480 gr
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Voltage" : "Gerilim"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            12 {siteLanguage === "en" ? "Volts" : "Volt"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Current" : "Akım"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            500-2000 mA
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Heat Measurement Range"
                              : "Isı Ölçüm Aralığı"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            0°C - 80°C (±1)
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Humidity Measurement Range"
                              : "Nem Ölçüm Aralığı"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            %0 - %95 RH (±3)
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Value Measurement Range"
                              : "Değer Ölçüm Aralığı"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            750 ms
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Transfer Period"
                              : "Transfer Süresi"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Adjustable Transfer Period"
                              : "Ayarlanabilir Transfer Süresi"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Lower And Upper Limit Information Can Be Defined"
                              : "Alt ve Üst Limit Bilgileri Tanımlanabiliyor"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Adjustable Transfer Period"
                              : "Ayarlanabilir Transfer Süresi"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Door Sensor"
                              : "Kapı Sensörü"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "None" : "Yok"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Water-Leakage"
                              : "Su-Sızıntı"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "None" : "Yok"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Protocols"
                              : "Protokoller"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            HTTP, HTTPS, FTP, SSH, Telnet
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-12 col-sm-12 waves-effect kolom-b"
                    data-bss-hover-animate="pulse"
                  >
                    <div
                      className="fitur-a d-flex align-items-center justify-content-center"
                      style={{ width: "100%", height: "250px" }}
                    >
                      <img
                        alt="img"
                        src="assets/img/product%20(2).png"
                        style={{ width: 320 }}
                      />
                    </div>
                    <div className="separator-fitur" />
                    <div>
                      <h4 className="heading-fitur">
                        <span
                          className="badge"
                          style={{
                            backgroundColor: "#b37b32",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          GM-EMS-401
                        </span>
                      </h4>
                      <h4 className="heading-fitur">
                        <span
                          className="badge me-1 my-1"
                          style={{
                            border: "1px solid #b37b32",
                            fontFamily: "Poppins, sans-serif",
                            color: "#b37b32",
                          }}
                        >
                          {siteLanguage === "en"
                            ? "Heat Sensor"
                            : "Isı Sensörü"}
                        </span>
                        <span
                          className="badge me-1 my-1"
                          style={{
                            border: "1px solid #b37b32",
                            fontFamily: "Poppins, sans-serif",
                            color: "#b37b32",
                          }}
                        >
                          {siteLanguage === "en"
                            ? "Humidity Sensor"
                            : "Nem Sensörü"}
                        </span>
                      </h4>
                      <p
                        className="paragraf-fitur"
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          minHeight: "275px",
                        }}
                      >
                        <span style={{ color: "rgb(128, 128, 128)" }}>
                          {/* {myLanguage?.our_info4} */}
                          {siteLanguage === "en"
                            ? "Guardmon EMS 401 is a device used to measure, monitor, record and keep under control environmental variables (temperature, humidity, smoke, air flow, etc.). EMS 401 does not require any computer/server connection to  work and can serve on its own. It is compatible with sensors of industrial standards, is easy to install, and offers flexible and reliable service."
                            : "Guardmon EMS 401, çevresel değişkenleri (sıcaklık, nem, duman, hava akışı vb.) ölçmek, izlemek, kaydetmek ve kontrol altında tutmak için kullanılan bir cihazdır. EMS 401'in çalışması için herhangi bir bilgisayar/sunucu bağlantısına ihtiyaç duyulmaz ve kendi başına hizmet verebilir. Endüstriyel standartlardaki sensörlerle uyumlu, kurulumu kolay, esnek ve güvenilir hizmet sunar."}
                        </span>
                      </p>
                      {/* <button
                        style={{ borderRadius: 8 }}
                        className="button"
                        type="button"
                        data-bss-hover-animate="pulse"
                        data-bs-toggle="modal"
                        data-bs-target="#product-2"
                        data-hover={`${myLanguage?.show}`}
                      >
                        <span>{myLanguage?.detail}</span>
                      </button> */}
                      <div className="mt-3">
                        <div className="row">
                          <div
                            className="col-12 p-3 text-center w-100"
                            style={{
                              backgroundColor: "#b37b32",
                              color: "white",
                              borderTopRightRadius: "8px",
                              borderTopLeftRadius: "8px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Technical Features"
                              : "Teknik Özellikler"}
                            <span
                              style={{ color: "#dddddd", fontSize: "12px" }}
                            >
                              {siteLanguage === "en"
                                ? "(In addition to GM-EMS-301)"
                                : "(GM-EMS-301'e ek olarak)"}
                            </span>
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Size" : "Boyut"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            380 x 105 x 35 mm
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Weight" : "Ağırlık"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            480 gr
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Voltage" : "Gerilim"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            12 {siteLanguage === "en" ? "Volts" : "Volt"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Current" : "Akım"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            500-2000 mA
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Screen" : "Ekran"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "None" : "Yok"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Alert Output"
                              : "Alert Output"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Available" : "Mevcut"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Photo-Cam Option"
                              : "Photo-Cam Option"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "None" : "Yok"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "GSM Specification"
                              : "GSM Şartnamesi"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Available" : "Mevcut"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Digital Input/Output"
                              : "Dijital Giriş/Çıkış"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "6 Inputs / 2 Outputs and 6 Analog Inputs Additional"
                              : "6 Giriş / 2 Çıkış ve 6 Analog Giriş Ek"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Door Sensor"
                              : "Kapı Sensörü"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "On/Off Magnetic Sensor (Optional)"
                              : "Açma/Kapama Manyetik Sensör (Opsiyonel)"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Water-Leakage"
                              : "Su-Sızıntı"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "One Wire Water Leakage Sensor"
                              : "Tek Telli Su Sızıntı Sensörü"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Protocols"
                              : "Protokoller"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            HTTP, HTTPS,SNMP Traps, Modbus, FTP, SSH, Telnet
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-12 col-sm-12 waves-effect kolom-c"
                    data-bss-hover-animate="pulse"
                  >
                    <div
                      className="fitur-a d-flex align-items-center justify-content-center"
                      style={{ width: "100%", height: "250px" }}
                    >
                      <img
                        alt="img"
                        src="assets/img/product%20(3).png"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="separator-fitur" />
                    <div>
                      <h4 className="heading-fitur">
                        <span
                          className="badge"
                          style={{
                            backgroundColor: "#b37b32",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          GM-EMS-501
                        </span>
                      </h4>
                      <h4 className="heading-fitur">
                        <span
                          className="badge me-1 my-1"
                          style={{
                            border: "1px solid #b37b32",
                            fontFamily: "Poppins, sans-serif",
                            color: "#b37b32",
                          }}
                        >
                          {siteLanguage === "en"
                            ? "Heat Sensor"
                            : "Isı Sensörü"}
                        </span>
                        <span
                          className="badge me-1 my-1"
                          style={{
                            border: "1px solid #b37b32",
                            fontFamily: "Poppins, sans-serif",
                            color: "#b37b32",
                          }}
                        >
                          {siteLanguage === "en"
                            ? "Humidity Sensor"
                            : "Nem Sensörü"}
                        </span>
                      </h4>
                      <p
                        className="paragraf-fitur"
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          minHeight: "275px",
                        }}
                      >
                        <span style={{ color: "rgb(128, 128, 128)" }}>
                          {/* {myLanguage?.our_info5} */}
                          {siteLanguage === "en"
                            ? "Guardmon EMS 501 is a device used to measure, monitor, record and keep under control environmental variables (temperature, humidity, smoke, air flow, etc.). EMS 501 does not require any computer/server connection to operate and can serve on its own. It is compatible with sensors of industrial standards, is easy to install, and offers flexible and reliable service."
                            : "Guardmon EMS 501, çevresel değişkenleri (sıcaklık, nem, duman, hava akışı vb.) ölçmek, izlemek, kaydetmek ve kontrol altında tutmak için kullanılan bir cihazdır. EMS 501'in çalışması için herhangi bir bilgisayar/sunucu bağlantısına ihtiyaç duyulmaz ve kendi başına hizmet verebilir. Endüstriyel standartlardaki sensörlerle uyumlu, kurulumu kolay, esnek ve güvenilir hizmet sunar."}
                        </span>
                      </p>
                      {/* <button
                        style={{ borderRadius: 8 }}
                        className="button"
                        type="button"
                        data-bss-hover-animate="pulse"
                        data-bs-toggle="modal"
                        data-bs-target="#product-3"
                        data-hover={`${myLanguage?.show}`}
                      >
                        <span>{myLanguage?.detail}</span>
                      </button> */}
                      <div className="mt-3">
                        <div className="row">
                          <div
                            className="col-12 p-3 text-center w-100"
                            style={{
                              backgroundColor: "#b37b32",
                              color: "white",
                              borderTopRightRadius: "8px",
                              borderTopLeftRadius: "8px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Technical Features"
                              : "Teknik Özellikler"}
                            <span
                              style={{ color: "#dddddd", fontSize: "12px" }}
                            >
                              {siteLanguage === "en"
                                ? "(In addition to GM-EMS-301)"
                                : "(GM-EMS-301'e ek olarak)"}
                            </span>
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Size" : "Boyut"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            380 x 105 x 35 mm
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Weight" : "Ağırlık"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            480 gr
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Voltage" : "Gerilim"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            12 {siteLanguage === "en" ? "Volts" : "Volt"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Current" : "Akım"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            500-2000 mA
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Screen" : "Ekran"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "2x16 LCD Screen"
                              : "2x16 LCD Ekran"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Alert Output"
                              : "Alert Output"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Available" : "Mevcut"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Photo-Cam Option"
                              : "Photo-Cam Option"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Available" : "Mevcut"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "GSM Specification"
                              : "GSM Şartnamesi"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "Available" : "Mevcut"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Digital Input/Output"
                              : "Dijital Giriş/Çıkış"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "8 Inputs / 3 Outputs "
                              : "8 Giriş / 3 Çıkış "}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Door Sensor"
                              : "Kapı Sensörü"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "On/Off Magnetic Sensor (Optional)"
                              : "Açma/Kapama Manyetik Sensör (Opsiyonel)"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Water-Leakage"
                              : "Su-Sızıntı"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en" ? "None" : "Yok"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-start"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            {siteLanguage === "en"
                              ? "Protocols"
                              : "Protokoller"}
                          </div>
                          <div
                            className="col-6 d-flex align-items-center justify-content-end text-end"
                            style={{
                              border: "1px solid black",
                              borderTop: "0px",
                            }}
                          >
                            HTTP, HTTPS,SNMP Traps, Modbus, FTP, SSH, Telnet
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
        </>
      )}
      <footer
        id="footer"
        className="footer"
        style={{ background: 'url("assets/img/hero_bg.png"), #212129' }}
      >
        <div className="container">
          <div className="row gy-4">
            <div className="col-md-12 col-lg-5 footer-info">
              <a className="d-flex align-items-center logo" href="/">
                <span style={{ fontFamily: '"Titillium Web", sans-serif' }}>
                  {myLanguage?.brand_name}
                </span>
              </a>
              <p style={{ fontFamily: "Poppins, sans-serif" }}>
                {myLanguage?.brand_detail}
              </p>
            </div>
            <div className="col-6 col-lg-2 footer-links">
              <h4
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                }}
              >
                {myLanguage?.brand_links}
              </h4>
              <ul>
                <li style={{ fontFamily: "Poppins, sans-serif" }}>
                  <a href="#hero">{myLanguage?.landing_home}</a>
                </li>
                <li style={{ fontFamily: "Poppins, sans-serif" }}>
                  <a href="#about">{myLanguage?.landing_about}</a>
                </li>
                <li style={{ fontFamily: "Poppins, sans-serif" }}>
                  <a href="#services">{myLanguage?.landing_services}</a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-lg-2 footer-links">
              <h4
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                }}
              >
                {myLanguage?.landing_services}
              </h4>
              <ul>
                <li style={{ fontFamily: "Poppins, sans-serif" }}>
                  <a href="#services">{myLanguage?.product_info2}</a>
                </li>
                <li style={{ fontFamily: "Poppins, sans-serif" }}>
                  <a href="#services">{myLanguage?.product_info4}</a>
                </li>
                <li style={{ fontFamily: "Poppins, sans-serif" }}>
                  <a href="#services">{myLanguage?.product_info6}</a>
                </li>
              </ul>
            </div>
            <div className="col-md-12 col-lg-3 text-center text-md-start footer-contact">
              <h4
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                }}
              >
                {myLanguage?.landing_contact}
              </h4>
              <p style={{ fontFamily: "Poppins, sans-serif" }}>
                <strong>
                  <span style={{ backgroundColor: "rgba(33, 33, 41, 0)" }}>
                    {myLanguage?.contact_info2}:{" "}
                  </span>
                </strong>
                <span style={{ backgroundColor: "rgba(33, 33, 41, 0)" }}>
                  {myLanguage?.contact_info3}
                </span>
                <br />
                <span style={{ backgroundColor: "rgba(33, 33, 41, 0)" }}>
                  {myLanguage?.owner_company_city}
                </span>
                <br />
                <strong>{myLanguage?.form_phone}: </strong>{" "}
                <span style={{ backgroundColor: "rgba(68, 68, 68, 0)" }}>
                  {myLanguage?.owner_company_phone}
                </span>
                <br />
                <strong>{myLanguage?.form_mail}: </strong>{" "}
                {myLanguage?.owner_company_mail}
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="copyright">
            <span style={{ fontFamily: "Poppins, sans-serif" }}>
              {" "}
              © Copyright{" "}
            </span>
            <strong>
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                }}
              >
                {myLanguage?.owner_company}{" "}
              </span>
            </strong>
            <span style={{ fontFamily: "Poppins, sans-serif" }}>
              {myLanguage?.all_rights}
            </span>
          </div>
          <div className="credits">
            <span style={{ fontFamily: "Poppins, sans-serif" }}>
              {myLanguage?.designed_by}{" "}
            </span>
            <div
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "bold",
              }}
            >
              {myLanguage?.software_team}
            </div>
          </div>
        </div>
      </footer>
      <a
        className="d-flex justify-content-center align-items-center scroll-top"
        href="/"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
    </div>
  );
};

export default Main;
