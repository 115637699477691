import React from "react";
import ReactDOM from "react-dom/client";
import { AuthContext } from "../contexts/AuthContext";
import { Helmet } from "react-helmet";
const root = ReactDOM.createRoot(document.getElementById("head"));
const Head = () => {
  const { myLanguage, myStyle } = React.useContext(AuthContext);
  setTimeout(() => {
    root.render(
      <div>
        <Helmet>
          <title>{myLanguage?.brand}</title>
          <link
            rel="shortcut icon"
            href={`${myStyle?.favicon}`}
          />
          <link
            rel="icon"
            href={`${myStyle?.favicon}`}
          />
          <link
            rel="apple-touch-icon"
            href={`${myStyle?.favicon}`}
          />

          <meta name="description" content="Guardmon" />
          <meta name="Abstract" content="Guardmon" />
          <meta name="Author" content="Uzel Bilişim, email@gmail.com" />
          <meta name="keywords" content="Guardmon" />
        </Helmet>
      </div>
    );
  }, 20);
};
export default Head;
